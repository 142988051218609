li {
  list-style-type: none;
}

.header .width {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #002766; */
  /* padding: 37px 45px; */
}

.header .text {
  margin-right: 45px;
}

.header ul {
  display: flex;
  justify-content: space-between;
}

.header ul li {
  margin-left: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
  color: #ffffff;
  padding: 10px;
}

.header .logo {
  margin: 45px 0;
  margin-left: 45px;
}

.header .menu {
  display: none;
}

.header ul li .sub-menu {
  width: 100%;
  position: absolute;
  left: 0;
  /* top: 20px; */
  margin-top: 10px;
  z-index: 1;
  height: 0px;
  background: white;
  border: none;

  opacity: 0;
  overflow: hidden;
  transform: translateY(-10px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.sub-menu .width {
  height: 0;
  border: none;
}

.header ul li:hover .sub-menu {
  height: 320px;
  transform: translateX(0);
  opacity: 1;
}

.header ul li:hover .sub-menu.two {
  height: 190px;
}

.header ul li:hover {
  color: #7399ef;
  cursor: pointer;
}

.header ul li:hover .sub-menu .width {
  height: 100%;
}

.header ul li .sub-menu .width .content {
  height: 100%;
  width: 100%;
  padding: 65px 130px;
  padding-right: 100px;
  color: black;
  /* background: blue; */
}

.content .three {
  display: flex;
  /* justify-content: space-between; */
}

.content .three:nth-child(2) {
  margin: 45px 0;
}

.content .three .spend {
  padding: 12px;
  width: 350px;
  margin-right: 100px !important;
}

.spend.hide {
  visibility: hidden;
}

.content .three .spend,
.content .three .spend a {
  display: flex;
  /* padding: 12px; */
  align-items: center;
}

.content .three .spend:hover {
  background: #e3ebfc;
  border: 0.5px solid #8fadf2;
  border-radius: 5px;
}

.content .three .spend:nth-child(2) {
  margin: 0 5px;
}

.content .three .spend .image {
  margin-right: 30px;
}

.content .three .spend .image img {
  width: 57px;
}

.content .three .spend .talks {
  font-size: 14px;
  line-height: 180%;
  color: #898a8d;
}

.content .three .spend .talks p:first-child {
  font-weight: 500;
  font-size: 21px;
  line-height: 160%;
  color: #000000;
}

.header .mobile {
  display: none;
}

.header .active {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  min-height: 100vh;
  z-index: 99;
  overflow: scroll;
  max-height: 100%;
}

.header .active .three {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background: #002766;
}

.header .products {
  padding: 10px 0;

  font-size: 16px;
  line-height: 152%;
  display: flex;
  align-items: center;
  margin: 0 20px;
  color: #121212;
  border-bottom: 0.3px solid #c6c6c6;
}

.header .noncon {
  height: 0;
  overflow: hidden;
}

.header .content {
  padding-top: 10px;
  margin: 0 20px;
}

.header .content .for {
  display: flex;
  margin-bottom: 22px;
}
.header .content .for a {
  display: flex;
  align-items: center;
}

.header .content .for .image {
  width: 46px;
  height: 46px;
  margin-right: 10px;
}

.header .content .for .image img {
  width: 100%;
}

/* .header .content .idea {
  margin-left: 20px;

  font-size: 14px;
} */

/* .header .content .idea p {
  margin-bottom: 20px;
  font-size: 14px;
} */

@media only screen and (max-width: 1400px) {
  .header ul li .sub-menu .width .content {
    padding: 65px;
  }

  .content .three .spend {
    margin-right: 50px !important;
  }
}

@media only screen and (max-width: 900px) {
  .header .menu {
    display: block;
    margin-right: 20px;
    width: 34px;
  }

  .header .text {
    display: none;
  }

  .header .content .for p:first-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 180%;
  }

  .header .content .for p:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    /* identical to box height, or 22px */

    color: #898a8d;
  }
}

@media only screen and (max-width: 600px) {
  .header .logo {
    margin-left: 20px;
    margin: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .header .logo {
    margin-left: 20px;
    /* margin: 20px; */
    margin-bottom: 0;
    margin-top: 0;
    width: 60px;
  }

  .header .logo img {
    width: 100%;
  }

  .header .width {
    height: 60px;
  }
}
