.loans {
  background: #f8faff;
  margin-top: 200px;
}

.loans .yourmoney .phonetext {
  flex-direction: row-reverse;
}

.loans .yourmoney .phonetext .phone {
  margin-top: -150px;
}

.loans .yourmoney .width {
  background: none;
}

.loans .yourmoney .phonetext .text .auto:nth-child(2) {
  margin-top: 0;
}

.loans .yourmoney .phonetext .text .auto {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1100px) {
  .loans .yourmoney .phonetext {
    flex-direction: column-reverse;
  }

  .loans .yourmoney .phonetext .phone {
    margin-top: 0;
  }
}

@media only screen and (max-width: 900px) {
  .loans {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 450px) {
  .loans .yourmoney .width {
    padding-top: 20px;
  }
}
