.getmore {
  margin-top: 170px;
}

.getmore .width {
  /* padding: 0 100px; */
}

.getmore .textphone {
  display: flex;
  justify-content: space-between;
  margin: 0 100px;
  background: #fbfbfb;
  border-radius: 53px;
  padding: 0 80px;
}

.getmore .textphone .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.getmore .textphone .text p {
  width: 520px;
}

.getmore .textphone p:first-child {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #abc2f5;
}

.getmore .textphone p:nth-child(2) {
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  margin: 12px 0;
  color: #7399ef;
}

.getmore .textphone .phone img {
  width: 100%;
}

.getmore .textphone .phone {
  margin-top: 90px;
}

@media only screen and (max-width: 1200px) {
  .getmore .textphone {
    flex-direction: column;
  }

  .getmore .textphone .phone {
    max-width: 600px;
  }
  .getmore .textphone {
    padding-top: 50px;
  }
  .getmore .textphone .text {
    margin-right: 0;
  }

  .getmore .textphone .text p {
    width: 100%;
  }
  .getmore .textphone {
    align-items: center;
  }
}

@media only screen and (max-width: 900px) {
  .getmore .textphone {
    padding: 23px;
    margin: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .getmore .textphone .phone {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .getmore .textphone {
    margin: 0;
    padding: 20px;
  }

  .getmore .textphone p:nth-child(2) {
    font-size: 28px;
  }
  .getmore {
    margin-top: 50px;
  }

  .getmore .textphone .phone {
    max-width: 270px;
  }
}
