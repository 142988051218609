.frequently .width {
  padding: 0 100px;
  margin-top: 200px;
}

.frequently .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  border-bottom: 0.5px solid #d6def0;
  padding: 20px 0;
}

.frequently .answer {
  text-align: center;
}

.frequently .answer p {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
  color: #cfd9ef;
}

.frequently .answer p:last-child {
  font-weight: 600;
  font-size: 37px;
  line-height: 120%;
  color: #002766;
  margin-top: 15px;
  margin-bottom: 70px;
}

.frequently
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  position: static;
}

.frequently
  .styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  outline: none;
  line-height: 180%;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  line-height: 180%;
}

@media only screen and (max-width: 900px) {
  .frequently .width {
    margin-top: 70px;
    padding: 0 50px;
  }
}

@media only screen and (max-width: 500px) {
  .frequently .width {
    padding: 0 20px;
  }
  .frequently .answer p {
    font-size: 18px;
  }
  .frequently .answer p:last-child {
    font-size: 24px;
  }

  .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-title__1YiiY
    .styles_row-title-text__1MuhU {
    font-size: 18px !important;
  }

  .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-content__QOGZd
    .styles_row-content-text__2sgAB {
    font-size: 16px !important;
  }
}
