.home .body {
  display: flex;
  justify-content: space-between;
  padding: 100px;
  /* pt */
}

.home .body .talk {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home .body .talk p:nth-child(2) {
  font-weight: 700;
  font-size: 65px;
  line-height: 130%;
  color: #ffffff;
}

.home .body .talk span {
  color: #7399ef;
}

.home .body .talk p:nth-child(3) {
  color: white;
  margin-top: 22px;
  margin-bottom: 37px;
}

.home .body .talk p:nth-child(4) {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #7399ef;
}

.home .body .talk .button {
  display: flex;
  margin-top: 18px;
}

.home .body .talk .button img:first-child {
  margin-right: 30px;
}

.home .body .img {
  /* position: relative; */
  display: flex;
  /* justify-content: flex-end; */
  width: 50%;
  width: 500px;
  align-items: center;
}

.home .body .img img {
  width: 100%;
  /* max-width: 300px; */
  /* position: relative; */
}

/* .home .body .img .phone {
  transform: translateX(-40%);
  animation: phone 5s infinite ease, phone1 5s infinite ease;
}

.home .body .img .card {
  transform: translateX(60%);
  animation: card 5s infinite ease;
} */

/* @keyframes phone {
  0% {
    transform: translateX(-40%);
    -webkit-transform: translateX(-40%);
    -ms-transform: translateX(-40%);
  }
  20% {
    transform: translateX(-5%);
    -webkit-transform: translateX(-5%);
    -ms-transform: translateX(-5%);
  }
  80% {
    transform: translateX(-5%);
    -webkit-transform: translateX(-5%);
    -ms-transform: translateX(-5%);
  }
  100% {
    transform: translateX(-5%);
    -webkit-transform: translateX(-5%);
    -ms-transform: translateX(-5%);
  }
}

@keyframes card {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
  }
  20% {
    transform: translateX(40%) rotate(-15deg);
    -webkit-transform: translateX(40%) rotate(-15deg);
    -ms-transform: translateX(40%) rotate(-15deg);
  }
  80% {
    transform: translateX(40%) rotate(-15deg);
    -webkit-transform: translateX(40%) rotate(-15deg);
    -ms-transform: translateX(40%) rotate(-15deg);
  }
  100% {
    transform: translateX(40%) rotate(-15deg);
    -webkit-transform: translateX(40%) rotate(-15deg);
    -ms-transform: translateX(40%) rotate(-15deg);
  }
} */

@media only screen and (max-width: 1330px) {
  .home .body .talk p:nth-child(2) {
    font-size: 55px;
  }
}

@media only screen and (max-width: 1300px) {
  .home .body .img {
    width: 400px;
  }

  .fifty {
    font-size: 40px;
  }

  .twentyone {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .home .body {
    padding: 100px 50px;
  }
}

@media only screen and (max-width: 1050px) {
  .home .body {
    flex-direction: column;
    align-items: center;
  }

  .home .body .talk {
    margin-bottom: 70px;
  }

  .home .body .img {
    justify-content: center;
    max-width: 500px;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .home .body .talk p:nth-child(3) br {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .fifty {
    font-size: 28px;
  }

  .twentyone {
    font-size: 14px;
  }

  .home .body {
    padding: 50px 20px;
  }

  .home .body .talk .button img {
    width: 40%;
  }

  .home .body .talk p:nth-child(2) {
    font-size: 38px;
  }

  .home .body .talk p:nth-child(2) br {
    display: none;
  }

  .home .body .img {
    justify-content: center;
    max-width: 350px;
  }
}

@media only screen and (max-width: 450px) {
  .home .body {
    padding-top: 0;
  }
  .home .body .talk p:nth-child(3) {
    margin-bottom: 20px;
  }
}
