.create .width {
  padding: 0 100px;
}

.credit .imagecreate {
  display: flex;
  margin-top: 95px;
}

.credit .imagecreate .create {
  width: 50%;

  min-width: 500px;
  max-width: 670px;
}

.credit .imagecreate .create .account {
  padding: 45px 26px;
  width: 100%;
  margin-bottom: 24px;
  cursor: pointer;
  color: #999999;
}

.bnpl .credit .imagecreate .create .account {
  padding: 25px 26px;
  margin-bottom: 0;
}

.credit .imagecreate .create .account p:first-child {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;

  margin-bottom: 6px;
}

.credit .imagecreate .create .account p:nth-child(2) {
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;

  max-width: 500px;
}

.credit .imagecreate .create .account.active {
  background: #e3ebfc;
  border-left: 5px solid #7399ef;
  color: #002766;
}

.credit .imagecreate .image {
  margin-right: 20px;
  max-height: 800px;
}

.credit .imagecreate .image img {
  max-height: 800px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .credit .imagecreate .create .account {
    padding: 30px 26px;
  }

  .bnpl .credit .imagecreate .image {
    width: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .credit .imagecreate .create .account {
    padding: 15px 26px;
  }
}

@media only screen and (max-width: 1100px) {
  .credit .imagecreate {
    flex-direction: column-reverse;
  }

  .credit .imagecreate .create .account {
    padding: 30px 26px;
  }

  .credit .imagecreate .image {
    margin-top: 20px;
  }

  .bnpl .credit .imagecreate .image {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .create .width {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 600px) {
  .credit .imagecreate .create {
    min-width: 100%;
    max-width: 100%;
  }

  .credit .imagecreate {
    margin-top: 30px;
  }

  .create .width {
    padding: 0 20px;
  }
}
