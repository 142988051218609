.bnpl .someof .img .image {
  margin-right: 30px;
}

/* .bnpl .someof .img .image:first-child{
    margin-left: 0;
} */

.bnpl .someof .img .image:last-child {
  margin-right: 0;
}

.bnpl .yourfav {
  margin-top: 100px;
}

.bnpl .home .body {
  justify-content: center;
  text-align: center;
  padding-bottom: 100px;
  padding-top: 150px;
}

.bnpl .credit .home {
  background: none;
  background-color: white;
}

@media only screen and (max-width: 500px) {
  .bnpl .home .body {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
