.someof {
  background: #171f30;
  margin-top: 150px;
}

.someof .width {
  padding: 100px;
}

.someof p {
  font-weight: 600;
  font-size: 37px;
  line-height: 120%;
  text-align: center;
  color: white;
}

.someof .img {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.someof .img img {
  width: 100%;
}

.someof .img .image {
  margin: 0 5px;
}

.someof .img .image img {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .someof .width {
    padding: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .someof {
    display: none;
  }
}
