.lock .inpic {
  background: #efefef;
  /* width: 100%; */
  margin: 100px;
  display: flex;
}

.lock .inpic .in {
  width: 40%;
  /* height: 200px; */
}

.lock .inpic .in:first-child {
  padding: 130px 65px 70px 65px;
  width: 60%;
}

.lock .inpic .in p:first-child {
  font-weight: 600;
  color: #002766;
  font-size: 37px;
  line-height: 120%;
}

.lock .inpic .in p:first-child span {
  text-decoration-line: line-through;
  color: #8fadf2;
}

.lock .inpic .in p:last-child {
  margin-top: 15px;
  color: #171f30;
}

.lock .inpic .in:nth-child(2) {
  display: flex;
  align-items: center;
}

.lock .inpic .in img {
  width: 100%;
}

@media only screen and (max-width: 1050px) {
  .lock .inpic {
    flex-direction: column;
    align-items: center;
  }

  .lock .inpic .in:first-child {
    width: 100%;
  }

  .lock .inpic .in:nth-child(2) {
    margin-top: 50px;
    justify-content: center;
  }

  .lock .inpic .in {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .lock .inpic {
    margin: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .lock .inpic .in:first-child {
    padding: 30px;
  }

  .lock .inpic .in p:first-child {
    font-size: 24px;
  }
}
