.business .header {
  border-bottom: 0.8px solid #ccd4e0;
}

.business .header ul li {
  color: #002766;
}

.business .home {
  background: #f1f5fd;
}

.business .home .body {
  background: #f1f5fd url("../../image/Group 33613.png") no-repeat top right;
}

.business .home .body .talk p:nth-child(2),
.business .home .body .talk p:nth-child(3) {
  color: #002766;
}

.business .home .body .button2 {
  padding: 17px 90px;
  max-width: 330px;
  background: #002766;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 450px) {
  .business .home .body .button2 {
    max-width: 100%;
    margin-top: 20px;
    padding: 17px 20px;
  }
}
