.footer {
  background: rgba(227, 235, 252, 0.2);
}

.footer .width {
  padding: 90px;
}

.footer .width .every {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
}

.footer .width .every .logo {
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #99a9c2;
  margin-right: 5%;
}

.footer .width .every .logo p {
  margin-top: 20px;
}

.footer .width .every .list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.footer .width .every .list .two {
  display: flex;
}

.footer .width .every .list ul {
  margin-left: 40px;
}

.footer .width .every .list ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #455c8f;
  margin-bottom: 10px;
  cursor: pointer;
}

.footer .width .every .list ul li.cs {
  color: black;
  font-weight: 700;
}

.footer .width .every .list ul li:first-child {
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: #002766;
}

.footer .socials {
  display: flex;
  margin-top: 70px;
}

.footer .socials img {
  width: 26px;
  margin-right: 16px;
}

@media only screen and (max-width: 1370px) {
  .footer .width .every .list ul {
    margin-left: 40px;
  }
  .footer .width .every .logo {
    margin-right: 3%;
  }
}

@media only screen and (max-width: 1240px) {
  .footer .width .every {
    /* flex-direction: column; */
  }
  .footer .width {
    padding: 50px;
  }
  .footer .width .every .list {
    margin-top: 30px;
    flex-wrap: wrap;
    /* justify-content: start; */
  }
  .footer .width .every .list ul {
    margin-left: 0;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1110px) {
  .footer .width .every {
    flex-direction: column;
  }

  .footer .width .every .list {
    justify-content: start;
  }
}

@media only screen and (max-width: 810px) {
  .footer .width .every .list {
    display: block;
  }
  .footer .width .every .list .two:nth-child(2),
  .footer .width .every .list .two:nth-child(3) {
    margin-top: 30px;
  }
  .footer .width .every .list ul {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .footer .width {
    padding: 50px 20px;
  }
  .footer .width .every .list ul li,
  .footer .width .every .logo {
    font-size: 14px;
  }
  .footer .width .every .list ul li:first-child {
    font-size: 18px;
  }
}

@media only screen and (max-width: 450px) {
  .footer .width .every .list ul:nth-child(2),
  .footer .width .every .list ul:nth-child(4) {
    margin-right: 0;
  }
  .footer .width .every .list ul li {
    margin-bottom: 8px;
  }

  .footer .socials {
    margin-top: 20px;
  }
}
