.blog1 .future {
  padding: 30px 0;
  text-align: center;
}

.blog1 .home .body {
  justify-content: center;
  min-height: 500px;
}

.blog1 .future p:first-child {
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  text-align: center;
  color: #002766;
}

.blog1 .future p:nth-child(2) {
  margin-top: 15px;
  margin-bottom: 22px;
}

.blog1 .future .socials a {
  margin-right: 10px;
}

.blog1 .future .socials img {
  width: 26px;
}

.blog1 .future .socials img:nth-child(2) {
  margin: 0 15px;
}

.blog1 .talk {
  padding: 140px;
  font-weight: 400;
  padding-bottom: 0;
  font-size: 21px;
  line-height: 160%;
  color: #000000;
}

.blog1 .talk ul li {
  list-style: decimal;
  margin-bottom: 30px;
}

.blog1 .talk span {
  font-weight: 700;
}

.blog1 .talk img {
  max-width: 900px;
  width: 100%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.blog1 .yinka {
  display: flex;
}

.blog1 .yinka .img {
  max-width: 400px;
  width: 30%;
  margin-right: 20px;
}

.blog1 .yinka .img img {
  width: 100%;
}

.blog1 .yinka .isa {
  /* max-width: 950px; */
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog .grow {
  margin-top: 100px;
}

@media only screen and (max-width: 1000px) {
  .blog1 .talk {
    padding: 30px;
  }

  .blog1 .future p:first-child {
    font-size: 24px;
  }

  .blog1 .future p:first-child {
    font-size: 16px;
  }

  .blog1 .talk {
    font-size: 16px;
  }

  .blog1 .yinka {
    flex-direction: column;
  }

  .blog1 .yinka .isa {
    width: 100%;
  }

  .blog1 .yinka .img {
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .blog1 .yinka .img {
    width: 100%;
  }

  .blog .grow {
    margin-top: 50px;
  }
}
