.grow {
  margin: 200px 100px;
}

.grow .first P:first-child {
  color: #002766;
  margin-bottom: 15px;
}

.grow .first P:nth-child(2) {
  width: 810px;
  margin-bottom: 50px;
}

.grow .second {
  display: flex;
  justify-content: space-between;
}

.grow .second .three {
  width: 442px;
}

.grow .second .three:nth-child(2) {
  margin: 0 30px;
}

.grow .second .three img {
  width: 100%;
}

.grow .second .three p:nth-child(2) {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
  color: #335285;
  min-height: 70px;
  margin-top: 10px;
}

.grow .second .three p:nth-child(3) {
  margin: 16px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
}

.grow .second .three p:last-child {
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  color: #7399ef;
}

@media only screen and (max-width: 1050px) {
  .grow {
    margin: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .grow .second {
    flex-direction: column;
  }
  .grow .first P:nth-child(2) {
    width: 100%;
  }
  .grow .second .three:nth-child(2) {
    margin: 20px 0;
  }

  .grow .second .three p:nth-child(2) {
    min-height: initial;
  }
}

@media only screen and (max-width: 600px) {
  .grow {
    margin: 30px 20px;
  }
  .grow .second {
    align-items: center;
  }

  .grow .second .three {
    width: 100%;
  }

  .grow .second .three p:nth-child(2) {
    font-size: 18px;
  }
  .grow .second .three p:nth-child(3) {
    font-size: 14px;
    margin: 10px 0;
  }
}
