.savings .yourmoney .phonetext .phone {
  margin-right: 20px;
}

.savings .yourmoney .phonetext .text .auto:nth-child(2) {
  margin-top: 0;
}

.savings .yourmoney .phonetext .text .auto {
  margin-bottom: 40px;
}

.savings .save {
  max-width: 650px;
  margin-bottom: 80px;
}

.savings .save p:first-child {
  margin-bottom: 8px;
}

.savings .yourmoney .phonetext .text .auto {
  width: 100%;
  max-width: 650px;
}

.savings .yourmoney .width {
  background: none;
}
