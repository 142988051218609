.take {
  margin-top: 200px;
  /* background: ; */
  background: #7399ef url("../../image/Group 33658.png") no-repeat right bottom;
}

.take .talkpic {
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
}

.take .talkpic .talk {
  color: #e3ebfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
}

.take .talkpic .talk p:nth-child(2) {
  margin: 15px 0;
}

.take .talkpic .talk p:nth-child(3) {
  margin-bottom: 18px;
  letter-spacing: -0.5px;
  color: #002766;
  font-weight: 600;
  font-size: 18px;
}

.take .talkpic .pic {
  /* width: 40%; */
  /* margin-top: 100px; */
  display: none;
}

.take .talkpic .pic img {
  width: 100%;
}

.take .talkpic .button {
  display: flex;
}

.take .talkpic .button img:first-child {
  margin-right: 30px;
}

@media only screen and (max-width: 1200px) {
  .take {
    margin-top: 100px;
    background: #7399ef;
    padding: 0 50px;
    padding-right: 0;
  }
  .take .talkpic {
    justify-content: center;
    flex-direction: column;
    padding-bottom: 0;
  }
  .take .talkpic .talk {
    padding-left: 0;
  }
  .take .talkpic .pic {
    display: block;
    max-width: 400px;
    margin-left: auto;
    margin-top: 30px;
  }
  .take .talkpic .button {
    margin-top: 30px;
  }

  .take .talkpic .button img {
    max-width: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .take {
    padding-left: 20px;
    padding-top: 40px;
  }

  .take .talkpic .talk {
    padding-right: 20px;
  }
  .take .talkpic {
    padding-top: 0;
  }

  .take .talkpic .talk p:nth-child(3) {
    margin-bottom: 0;
    font-size: 14px;
  }
}

@media only screen and (max-width: 420px) {
  .take .talkpic .button img {
    width: 120px;
  }
  .take .talkpic .button img:first-child {
    margin-right: 10px;
  }

  .take .talkpic .talk p:nth-child(2) br {
    display: none;
  }
}

@media only screen and (max-width: 300px) {
  .take .talkpic .button img {
    width: 90px;
  }
}
