.credit .home {
  /* background: #e3ebfc; */
  background: #e3ebfc url("../../image/Group 33613 (1).png") no-repeat top right;
}

.credit .home .body {
  /* background: #e3ebfc url("../../image/Group 33613 (1).png") no-repeat top right; */
  padding-bottom: 0;
}

.credit .home .body .img {
  justify-content: center;
  width: 400px;
}

/* .credit .home .body .img img {
  width: 400px;
} */

.credit .service {
  text-align: center;
  margin-top: 150px;
  padding: 0 100px;
}

.credit .service p:first-child {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
  color: #8fadf2;
}

.credit .service p:nth-child(2) {
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  color: #002766;
  margin: 20px 0;
}

.credit .service p:nth-child(3) {
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  color: #002766;
}

.cspi {
  /* width: 120px; */

  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
  margin-bottom: 21px;
}

.cspi span {
  background: #7399ef;
  color: #ffffff !important;
  border-radius: 37px;
  padding: 5px 10px;
  /* display: block; */
  /* text-align: center; */
}

.credit .home .body .talk p:nth-child(2),
.credit .home .body .talk p:nth-child(3),
.home .body .talk p:nth-child(4) {
  color: #002766;
}

@media only screen and (max-width: 1310px) {
  .credit .home .body .talk p:nth-child(2) {
    font-size: 60px;
  }

  .credit .home .body .img {
    width: 350px;
  }
}

@media only screen and (max-width: 1200px) {
  .credit .home .body .talk p:nth-child(2) {
    font-size: 48px;
  }
}

@media only screen and (max-width: 900px) {
  .credit .home .body .talk p:nth-child(2) {
    font-size: 37px;
  }
  .home .body .talk p:nth-child(4) {
    font-size: 16px;
  }

  .credit .service p:nth-child(2) {
    font-size: 28px;
  }
  .credit .service p:first-child,
  .credit .service p:nth-child(3) {
    font-size: 18px;
  }
  .credit .service {
    padding: 0 50px;
  }

  .credit .service {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 450px) {
  .credit .home .body {
    padding-top: 50px;
  }

  .credit .imagecreate .create .account p:first-child {
    font-size: 18px;
  }
  .credit .imagecreate .create .account p:nth-child(2) {
    font-size: 16px;
  }

  .credit .home .body .img{
    width: 100%;
  }
}
