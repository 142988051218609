.yourmoney {
  background: #f8faff;
  /* background: url("../../image/Mask\ Group.svg") no-repeat right;
  background-size: cover; */
}

.yourmoney .width {
  padding: 0 100px;
}

.yourmoney .put {
  /* width: 730px; */
  padding-top: 100px;
}

.yourmoney .put p:first-child {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #abc2f5;
}

.yourmoney .put p:nth-child(2) {
  font-weight: 700;
  font-size: 49px;
  margin: 15px 0;
  color: #002766;
}

.yourmoney .phonetext {
  margin-top: 75px;
  display: flex;
  justify-content: space-between;
}

.yourmoney .phonetext .text .auto {
  width: 511px;
  display: flex;
}

.yourmoney .phonetext .text .auto p:first-child {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
}

.yourmoney .phonetext .text .auto:nth-child(2) {
  margin-top: 75px;
}

.yourmoney .phonetext .text .talk {
  /* display: flex; */
  margin-left: 20px;
}

.yourmoney .phonetext .phone img {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .yourmoney .phonetext {
    flex-direction: column-reverse;
  }

  .yourmoney .phonetext .phone {
    max-width: 600px;
  }
}

@media only screen and (max-width: 1050px) {
  .yourmoney .width {
    background-position: right;
  }

  .yourmoney .put {
    margin-top: 50px;
    padding-top: 0;
  }

  .yourmoney .width {
    /* padding: 0 20px; */
    padding-top: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .yourmoney .width {
    padding: 0 50px;
  }
  .yourmoney .put p:nth-child(2) br {
    display: none;
  }
  .yourmoney .put p:nth-child(2) {
    font-size: 28px;
  }
  .yourmoney .phonetext .text .auto {
    width: 100%;
  }

  .yourmoney .phonetext .text .auto:nth-child(2) {
    margin-top: 30px;
  }

  .twentyone {
    font-size: 16px;
  }
  .yourmoney .put br {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .yourmoney .phonetext .phone {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .yourmoney .width {
    padding: 0 20px;
    padding-top: 50px;
  }
  .yourmoney .phonetext .text .auto img {
    width: 60px;
  }
}

@media only screen and (max-width: 500px) {
  .yourmoney .phonetext .text .auto p:first-child {
    font-size: 18px;
  }
}
