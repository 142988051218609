.yourfav {
  margin-top: 200px;
}

.yourfav .width {
  padding: 0 100px;
}

.yourfav .yourmoney {
  background: white;
}

.yourfav .yourmoney span {
  color: #7399ef;
}

.yourfav .image {
  display: flex;
  justify-content: space-between;
}

.yourfav .image.g {
  margin-top: 60px;
  margin-bottom: 30px;
}

.yourfav .image .gal:nth-child(2) {
  margin: 0 20px;
}

.yourfav .image .gal img {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .yourfav .width {
    padding: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .yourfav .width {
    padding: 20px;
  }
  .yourfav {
    margin-top: 50px;
  }
}
