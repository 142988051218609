.control {
  background: #f1f5fd;
  margin-top: 200px;

  background: #f1f5fd url("../../image/Group 33613.png") no-repeat top right;
  /* background-size: cover; */
}

.control .pictalk {
  height: 600px;
  display: flex;
  justify-content: space-between;
  padding: 0 100px;

}

.control .pictalk .pic {
  /* display: flex; */
  position: relative;
}

.control .pictalk .pic img {
  /* width: 100%; */
  position: absolute;
  bottom: 0;
}

.control .pictalk .talk {
  width: 570px;
  /* margin-left: auto; */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #00173d;
}

.control .pictalk .talk p:first-child {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #abc2f5;
}

.control .pictalk .talk p:nth-child(2) {
  margin-top: 6px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1330px) {
  .control .pictalk .pic img {
    width: 400px;
  }
}

@media only screen and (max-width: 1200px) {
  .control .pictalk {
    flex-direction: column-reverse;
    align-items: center;
  }
  .control .pictalk .pic img {
    position: static;
  }
  .control .pictalk {
    height: auto;
    padding: 0 50px;
    padding-top: 50px;
  }
  .control .pictalk .pic {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .control .pictalk .talk {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .control .pictalk {
    padding-left: 20px;
    padding-right: 20px;
  }

  .control .pictalk .pic img {
    max-width: 400px;
    width: 100%;
  }

  .control {
    margin-top: 50px;
  }
}
