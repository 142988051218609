.getaccess {
  background: #171f30;
  margin-top: 150px;
}

.getaccess .imagetalk {
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  height: 500px;
}

.getaccess .imagetalk .talk {
  color: #ffffff;
  /* width: 629px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  /* padding: 100px 0; */
}

.getaccess .imagetalk .talk p:last-child {
  margin-top: 17px;
}

.getaccess .imagetalk .talk span {
  color: #7399ef;
}

.getaccess .imagetalk .image {
  width: 30%;
  position: relative;
}

.getaccess .imagetalk .image img {
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 1200px) {
  .getaccess .imagetalk .image img {
    position: static;
  }
  .getaccess .imagetalk {
    flex-direction: column-reverse;
    align-items: center;
  }
  .getaccess .imagetalk {
    height: auto;
    padding: 0 50px;
  }
  .getaccess .imagetalk .talk {
    margin-top: 50px;
  }
  .getaccess .imagetalk .image {
    min-width: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .getaccess .imagetalk {
    padding: 0 20px;
  }
  .getaccess .imagetalk .talk p:last-child br {
    display: none;
  }
  .getaccess .imagetalk .talk {
    margin-left: 0;
  }

  .getaccess .imagetalk .image {
    min-width: 340px;
  }
  .getaccess {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 430px) {
  .getaccess .imagetalk .talk br {
    display: none;
  }

  .getaccess .imagetalk .image {
    min-width: 280px;
  }
}
