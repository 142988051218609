.thecredit {
  padding: 0 100px;
  margin-top: 150px;
}

.thecredit .service {
  text-align: center;
  /* padding: 0 100px; */
}

.thecredit .service p:first-child {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
  color: #8fadf2;
}

.thecredit .service p:nth-child(2) {
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  color: #002766;
  margin: 20px;
}

.thecredit .service p:last-child {
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  color: #002766;
}

.thecredit .working {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.thecredit .working .float {
  border: 1px solid #e3ebfc;
  box-shadow: 0px 4px 11px 4px rgba(189, 203, 236, 0.14);
  border-radius: 15px;
  max-width: 675px;
  width: 50%;
}

.thecredit .working .float:first-child {
  margin-right: 40px;
}

.thecredit .working .float .img {
  margin-bottom: 30px;
}

.thecredit .working .float .img img {
  width: 100%;
}

.thecredit .working .float .talk {
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 40px;
}

.thecredit .working .float .talk p:first-child {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
  color: #002766;
}

.thecredit .working.two {
  display: block;
}

.thecredit .working.two .float.two {
  width: 100%;
  max-width: 100%;
}

.thecredit .working .float .talk p:nth-child(2) {
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  color: #002766;
  margin-top: 10px;
  margin-bottom: 145px;
}

.thecredit .working .float .talk p:nth-child(3) {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #002766;
}

.thecredit .working .float .talk p {
  display: flex;
  align-items: center;
}
.thecredit .working .float .talk p img {
  width: 24px;
  margin-left: 10px;
}

@media only screen and (max-width: 1050px) {
  .thecredit {
    padding: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .thecredit {
    padding: 0 50px;
    margin-bottom: 100px;
  }
  .thecredit .service p:nth-child(2) {
    font-size: 18px;
  }
  .thecredit .service p:first-child {
    font-size: 16px;
  }
  .thecredit .service p:last-child {
    font-size: 18px;
  }

  .thecredit .working .float .talk {
    padding: 20px;
  }

  .thecredit .working .float {
    width: 100%;
  }

  .thecredit .working .float:first-child {
    margin-right: 0;
    margin-bottom: 50px;
  }

  .thecredit .working {
    flex-direction: column;
  }
  .thecredit .working .float .talk p {
    font-size: 16px !important;
  }

  .thecredit .working .float .talk p:first-child {
    font-size: 18px;
  }

  .thecredit .working .float .talk p:nth-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .thecredit {
    padding: 0 20px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 600px) {
}
