.terms .ourterms {
  background: #e3ebfc;
  padding: 70px;
  margin-bottom: 120px;
}

a.color {
  color: #002766;
  font-weight: 500;
}

.terms .tcpp {
  display: flex;
  margin-top: 60px;
  box-shadow: 0px 20px 42px rgba(0, 0, 0, 0.03);

  color: #999999;
  font-size: 18px;
}

.terms .tcpp .width {
  display: flex;
}

.terms .tcpp p {
  cursor: pointer;
}

.terms .tcpp p:first-child {
  margin-right: 100px;
}

.terms .tcpp p.active {
  color: #121212;
  padding-bottom: 16px;
  border-bottom: 4px solid #f4811e;
  /* transition: all 2s ease-in-out; */
}

.terms .ourterms .top {
  font-weight: 700;
  font-size: 65px;
  line-height: 130%;
  text-align: center;
  color: #002766;
}

.terms .ourterms .top p:nth-child(2) {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.terms .ourterms .input {
  max-width: 900px;

  border-radius: 14px;
  margin: auto;
  position: relative;
}

.terms .ourterms .input {
  align-items: center;
  display: flex;
  /* margin: 10px 0; */
}

.terms .ourterms .input img {
  position: absolute;
  right: 30px;
  /* transform: rotate(180deg); */
}

.terms .ourterms .input input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.02);
  border-radius: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #c6c6c6;
  cursor: pointer;
}

.terms .ourterms .input input:focus {
  border: 1px solid #002766;
}

.terms .ourterms .input .drop {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 14px;
  position: absolute;
  bottom: -380px;
  padding: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: #121212;
  height: 360px;
  width: 430px;
  overflow-y: scroll;
}

.terms .ourterms .input .drop p {
  margin-bottom: 20px;
  cursor: pointer;
}

.terms .ourterms .input .drop p:last-child {
  margin-bottom: 0;
}

.terms .ourterms .input .none {
  display: none;
}

p.terms {
  margin: 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
}

p.terms span {
  font-weight: 700;
  font-size: 23px;
  line-height: 152%;
}

p.terms span.big {
  font-weight: 600;
  font-size: 37px;
  line-height: 120%;
}

@media only screen and (max-width: 600px) {
  p.terms {
    font-size: 14px;
    margin: 30px;
  }

  p.terms span {
    font-weight: 700;
    font-size: 18px;
    line-height: 152%;
  }

  p.terms span.big {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
  }

  .terms .ourterms .top {
    font-size: 24px;
  }
  .terms .ourterms .top p:nth-child(2) {
    font-size: 16px;
  }

  .terms .ourterms {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 0 50px;
  }

  .terms .ourterms .input input {
    font-size: 16px;
  }

  .terms .tcpp p {
    font-size: 16px;
  }

  .terms .ourterms .input input {
    padding-right: 70px;
    font-size: 14px;
  }

  .terms .ourterms .input .drop {
    width: auto;
  }
}
