.payment .yourmoney {
  background: none;
}

.payment .yourmoney .phonetext {
  flex-direction: row-reverse;
}

.payment .yourmoney .phonetext .phone {
  margin-right: 0;
  margin-left: 20px;
}

.payment .yourmoney .phonetext .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .payment .yourmoney .phonetext {
    flex-direction: column-reverse;
  }
  .payment .yourmoney .phonetext .phone {
    margin-left: 0;
    margin-top: 20px;
  }

  .payment .yourmoney .put {
    margin-top: 0;
  }
}
