/* CSS for the cookie pop-up */
.cookie-popup {
  position: fixed;
  bottom: 60px;
  right: 60px;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 300px;
}

.cookie-content {
  display: flex;
  flex-direction: column;
}

.cookie-content p {
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
}

.buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.buttons button:hover {
  background-color: #0056b3;
}

/* Optionally, you can add a CSS animation for the pop-up */
@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

/* Animate the pop-up when it appears and disappears */
.cookie-popup.slide-in {
  animation: slideIn 0.3s ease-out;
}

.cookie-popup.slide-out {
  animation: slideOut 0.3s ease-in;
}
