div {
  /* border: 2px solid black; */
}

.eighteen {
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
}

.twentyone {
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
}

.fifty {
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
}

.home {
  background: #002766;
}

.width {
  max-width: 1500px;
  margin: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .fifty {
    font-size: 28px;
  }
  .eighteen {
    font-size: 16px;
  }
}
